<template>
  <CalendarEventDetailsView
    :eventDetails="eventDetails"
    :userType="UserType.Buyer"
    @eventCancel="onEventCancel"
  />
  <ConfirmDialog
    v-model:visible="confirmationVisible"
    :title="t('calendar.event_details.cancel.confirm_title')"
    :submitPending="submitPending"
    @confirm="onDialogConfirm"
    @reject="onDialogReject"
  />
</template>

<script lang="ts">
import { useAppStore } from '@bd/client/store'
import {
  CalendarEventDetailsView,
  CalendarEventMessages,
  ConfirmDialog,
  UserType,
} from '@bd/components'
import { useToast } from 'primevue/usetoast'
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    CalendarEventDetailsView,
    ConfirmDialog,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const router = useRouter()
    const toast = useToast()
    const eventDetails = computed(() => store.state.calendar?.eventDetails)
    const eventDetailsContent = computed(() => eventDetails.value?.content)

    const confirmationVisible = ref(false)
    const submitPending = ref(false)

    const closeConfirmationDialog = () => {
      confirmationVisible.value = false
    }

    const onEventCancel = () => {
      confirmationVisible.value = true
    }

    const onDialogConfirm = async () => {
      submitPending.value = true
      try {
        await store.dispatch(
          'calendar/cancelCalendarEvent',
          eventDetailsContent.value?.userCalendarEventId,
        )
        toast.add(CalendarEventMessages.cancelSuccess)
        closeConfirmationDialog()
        router.push({ name: 'Calendar' })
      } catch (err) {
        toast.add(CalendarEventMessages.cancelError)
      } finally {
        submitPending.value = false
      }
    }

    const onDialogReject = () => {
      closeConfirmationDialog()
    }

    return {
      t,
      UserType,
      eventDetails,
      confirmationVisible,
      submitPending,
      onEventCancel,
      onDialogConfirm,
      onDialogReject,
    }
  },
})
</script>

<style lang="scss" scoped></style>
